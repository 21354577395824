var nav,
    navUnscrolledHeight = 0,
    navScrolledHeight = 0,
    navUtility,
    navUtilityHeight = 0,
    navUtilityUnscrolledHeight = 0, 
    navUtilityScrolledHeight = 0, 
    navHeader,
    navHeaderHeight = 0,
    navHeaderUnscrolledHeight = 0,
    navMenu,
    navMenuHeight = 0,    
    navOverlay = false,
    navTransparent = false,
    navTransparentXs = false,
    navTransparentSm = false,
    navTransparentMdOrLg = false,
    navFixed = false,
    navScrolled = false,
    navOutOfSight = false,
    floatingProjectSections,
    scrollYOffset = 0,
    smoothScrolling = false,
    scrollingUp = false,
    navRetractOnScrollUpXs = false,
    navRetractOnScrollUpSm = false,
    navRetractOnScrollUpMdOrLg = false,
    navMenuTopY,
    navMenuBottomY,    
    navScrollAtXs,
    navScrollAtSm,
    navScrollAtMdOrLg;

$(document).ready(function() {
    "use strict";
    
    // Check if this is a mobile device based on User Agent
    var isMobile = function() {
        var md = new MobileDetect(window.navigator.userAgent || navigator.vendor || window.opera);
        return md.mobile() !== null;
        //return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera);
    }();
    
    var isHome = $('body').hasClass('home');

    // Smooth scroll to inner links

    $('a[href^="#"]').each(function() {
        var href = $(this).attr('href');

        // If there's no element on the page with an id that corresponds to the href, then try the fallback href, if provided
        if ($(href).length == 0) {  
            var fallbackHref = $(this).attr('data-fallback-href');

            if (fallbackHref !== undefined) {
                $(this).attr('href', fallbackHref);
            }
        }

    });

    
    if ($('a[href^="#"]').length) {        
        $('a[href^="#"]').smoothScroll({            
            speed: 800,
            beforeScroll: function() {
                console.log("Before scroll!");
                smoothScrolling = true;
            },
            afterScroll: function() {
                smoothScrolling = false;
            }
        });
    }
    
    // Open external links in new window
    $('a').each(function() {
        if ($(this).prop('hostname') !== undefined && ($(this).prop('hostname').length > 0 || ($(this).attr('href') !== undefined && $(this).attr('href').length > 0)) && location.hostname !== $(this).prop('hostname')) {
            $(this).attr('target', '_blank');
        }
    });

         
    // Append .background-image-holder <img>'s as CSS backgrounds

    $('.background-image-holder').each(function() {
        var imgSrc = $(this).children('img').attr('src');
        $(this).css('background-image', 'url("' + imgSrc + '")');
        $(this).children('img').hide();
        //$(this).css('background-position', 'initial');
    });

    // Fade in background images

    setTimeout(function() {
        $('.background-image-holder').each(function() {
            $(this).addClass('fadeIn');
        });
    }, 200);
    
    // Initialize Tooltips

    $('[data-toggle="tooltip"]').tooltip();

    // Icon bulleted lists

    $('ul[data-bullet]').each(function(){
        var bullet = $(this).attr('data-bullet');
        $(this).find('li').prepend('<i class="'+bullet+'"></i>');
    });

    // Progress Bars

    $('.progress-bar').each(function() {
        $(this).css('width', $(this).attr('data-progress') + '%');
    });

    // Menu dropdown positioning

    $('.menu > li > ul').each(function() {
        var menu = $(this).offset();
        var farRight = menu.left + $(this).outerWidth(true);
        if (farRight > $(window).width() && !$(this).hasClass('mega-menu')) {
            $(this).addClass('make-right');
        } else if (farRight > $(window).width() && $(this).hasClass('mega-menu')) {
            var isOnScreen = $(window).width() - menu.left;
            var difference = $(this).outerWidth(true) - isOnScreen;
            $(this).css('margin-left', -(difference));
        }
    });

    // Mobile Menu

    $('.menu li').click(function(e) {
        if (!e) e = window.event;
        e.stopPropagation();
        if ($(this).find('ul').length) {
            $(this).toggleClass('toggle-sub');
        } else {
            $(this).parents('.toggle-sub').removeClass('toggle-sub');
        }
    });
    
    $('.module.widget-handle').click(function() {
        $(this).toggleClass('toggle-widget-handle');
    });

    $('.search-widget-handle .search-form input').click(function(e){
        if (!e) e = window.event;
        e.stopPropagation();
    });
    
    // Offscreen Nav
    
    if ($('.offscreen-toggle').length){
        $('body').addClass('has-offscreen-nav');
    }
    else{
        $('body').removeClass('has-offscreen-nav');
    }
    
    $('.offscreen-toggle').click(function(){
        $('.main-container').toggleClass('reveal-nav');
        $('nav').toggleClass('reveal-nav');
        $('.offscreen-container').toggleClass('reveal-nav');
    });
    
    $('.main-container').click(function(){
        if ($(this).hasClass('reveal-nav')){
            $(this).removeClass('reveal-nav');
            $('.offscreen-container').removeClass('reveal-nav');
            $('nav').removeClass('reveal-nav');
        }
    });
    
    $('.offscreen-container a').click(function(){
        $('.offscreen-container').removeClass('reveal-nav');
        $('.main-container').removeClass('reveal-nav');
        $('nav').removeClass('reveal-nav');
    });

    // Populate filters
    
    $('.projects').each(function() {

        var filters = "";

        $(this).find('.project').each(function() {

            var filterTags = $(this).attr('data-filter').split(',');

            filterTags.forEach(function(tagName) {
                if (filters.indexOf(tagName) == -1) {
                    filters += '<li data-filter="' + tagName + '">' + capitaliseFirstLetter(tagName) + '</li>';
                }
            });
            $(this).closest('.projects')
                .find('ul.filters').empty().append('<li data-filter="all" class="active">All</li>').append(filters);
        });
    });

    $('.filters li').click(function() {
        var filter = $(this).attr('data-filter');
        $(this).closest('.filters').find('li').removeClass('active');
        $(this).addClass('active');

        $(this).closest('.projects').find('.project').each(function() {
            var filters = $(this).attr('data-filter');

            if (filters.indexOf(filter) == -1) {
                $(this).addClass('inactive');
            } else {
                $(this).removeClass('inactive');
            }
        });

        if (filter == 'all') {
            $(this).closest('.projects').find('.project').removeClass('inactive');
        }
    });

    // Twitter Feed
       jQuery('.tweets-feed').each(function(index) {
           jQuery(this).attr('id', 'tweets-' + index);
       }).each(function(index) {
           
           var TweetConfig = {
               "id": jQuery('#tweets-' + index).attr('data-widget-id'),
               "domId": '',
               "maxTweets": jQuery('#tweets-' + index).attr('data-amount'),
               "enableLinks": true,
               "showUser": true,
               "showTime": true,
               "dateFunction": '',
               "showRetweet": false,
               "customCallback": handleTweets
           };
           function handleTweets(tweets) {
               var x = tweets.length;
               var n = 0;
               var element = document.getElementById('tweets-' + index);
               var html = '<ul class="slides">';
               while (n < x) {
                   html += '<li>' + tweets[n] + '</li>';
                   n++;
               }
               html += '</ul>';
               element.innerHTML = html;
               return html;
           }
           twitterFetcher.fetch(TweetConfig);
       });

    // Instagram Feed
    
    if ($('.instafeed').length){
        jQuery.fn.spectragram.accessData = {
            accessToken: '1406933036.dc95b96.2ed56eddc62f41cbb22c1573d58625a2',
            clientID: '87e6d2b8a0ef4c7ab8bc45e80ddd0c6a'
        };  

        $('.instafeed').each(function() {
            var feedID = $(this).attr('data-user-name');
            $(this).children('ul').spectragram('getUserFeed', {
                query: feedID,
                max: 12
            });
        });
    }   

   

    // Flickr Feeds

    if ($('.flickr-feed').length){
        $('.flickr-feed').each(function(){
            var userID = $(this).attr('data-user-id');
            var albumID = $(this).attr('data-album-id');
            $(this).flickrPhotoStream({ id: userID, setId: albumID, container: '<li class="masonry-item" />' });   
            setTimeout(function(){
                initializeMasonry();
                window.dispatchEvent(new Event('resize'));
            }, 1000); 
        });

    }

    // Image Sliders
    if ($('.slider-all-controls, .slider-paging-controls, .slider-arrow-controls, .slider-thumb-controls, .logo-carousel').length){
        $('.slider-all-controls').flexslider({
            animation: "slide",
            directionNav: true,
            controlNav: true,
            slideshow: false,
            start: function(slider){
                if (slider.find('.slides li:first-child').find('.fs-vid-background video').length){
                   slider.find('.slides li:first-child').find('.fs-vid-background video').get(0).play();
                }
            },
            after: function(slider){
                if (slider.find('.fs-vid-background video').length){
                    if (slider.find('li:not(.flex-active-slide)').find('.fs-vid-background video').length){
                        slider.find('li:not(.flex-active-slide)').find('.fs-vid-background video').get(0).pause();
                    }
                    if (slider.find('.flex-active-slide').find('.fs-vid-background video').length){
                        slider.find('.flex-active-slide').find('.fs-vid-background video').get(0).play();
                    }
                }
            }
        });

        $('.slider-paging-controls').flexslider({
            animation: "slide",
            directionNav: false
        });

        $('.slider-arrow-controls').flexslider({
            controlNav: false
        });

        $('.slider-thumb-controls .slides li').each(function() {
            var imgSrc = $(this).find('img').attr('src');
            $(this).attr('data-thumb', imgSrc);
        });
        $('.slider-thumb-controls').flexslider({
            animation: "slide",
            controlNav: "thumbnails",
            directionNav: true
        });
        $('.logo-carousel').flexslider({
            minItems: 1,
            maxItems: 4,
            move: 1,
            itemWidth: 200,
            itemMargin: 0,
            animation: "slide",
            slideshow: true,
            slideshowSpeed: 3000,
            directionNav: false,
            controlNav: false
        });
    }
    
    // Lightbox gallery titles
    
    $('.lightbox-grid li a').each(function(){
        var galleryTitle = $(this).closest('.lightbox-grid').attr('data-gallery-title');
        $(this).attr('data-lightbox', galleryTitle);
    });

    // Prepare embedded video modals

    $('iframe[data-provider]').each(function(){
        var provider = jQuery(this).attr('data-provider');
        var videoID = jQuery(this).attr('data-video-id');
        var autoplay = jQuery(this).attr('data-autoplay');
        var vidURL = '';

        if (provider == 'vimeo'){
            vidURL = "http://player.vimeo.com/video/"+videoID+"?badge=0&title=0&byline=0&title=0&autoplay="+autoplay;
            $(this).attr('data-src', vidURL);
        }else if (provider == 'youtube'){
            vidURL = "https://www.youtube.com/embed/"+videoID+"?showinfo=0&autoplay="+autoplay;
            $(this).attr('data-src', vidURL);
        }else{
            console.log('Only Vimeo and Youtube videos are supported at this time');
        }
    });
    
    // Multipurpose Modals
    
    jQuery('.foundry_modal[modal-link]').remove();

    if ($('.foundry_modal').length && (!jQuery('.modal-screen').length)){
        // Add a div.modal-screen if there isn't already one there.
        var modalScreen = jQuery('<div />').addClass('modal-screen').appendTo('body');

    }

    jQuery('.foundry_modal').click(function(){
        jQuery(this).addClass('modal-acknowledged');
    });

    jQuery(document).on('wheel mousewheel scroll', '.foundry_modal, .modal-screen', function(evt){
        $(this).get(0).scrollYOffset += (evt.originalEvent.deltaY);
        return false;
    });
    
    $('.modal-container:not([modal-link])').each(function(index) {
        if (jQuery(this).find('iframe[src]').length){
            jQuery(this).find('.foundry_modal').addClass('iframe-modal');
            var iframe = jQuery(this).find('iframe');
            iframe.attr('data-src',iframe.attr('src'));
            iframe.attr('src', '');

        }
        jQuery(this).find('.btn-modal').attr('modal-link', index);

        // Only clone and append to body if there isn't already one there
        if (!jQuery('.foundry_modal[modal-link="'+index+'"]').length){
            jQuery(this).find('.foundry_modal').clone().appendTo('body').attr('modal-link', index).prepend(jQuery('<i class="ti-close close-modal">'));
        }
    });
    
    $('.btn-modal').unbind('click').click(function(){
        var linkedModal = jQuery('.foundry_modal[modal-link="' + jQuery(this).attr('modal-link') + '"]'),
            autoplayMsg = "";
        jQuery('.modal-screen').toggleClass('reveal-modal');
        if (linkedModal.find('iframe').length){
            if (linkedModal.find('iframe').attr('data-autoplay') === '1'){
                autoplayMsg = '&autoplay=1';
            }
            linkedModal.find('iframe').attr('src', (linkedModal.find('iframe').attr('data-src') + autoplayMsg));
        }
        if (linkedModal.find('video').length){
            linkedModal.find('video').get(0).play();
        }
        linkedModal.toggleClass('reveal-modal');
        return false; 
    });
    
    // Autoshow modals
    
    $('.foundry_modal[data-time-delay]').each(function(){
        var modal = $(this);
        var delay = modal.attr('data-time-delay');
        modal.prepend($('<i class="ti-close close-modal">'));
        if (typeof modal.attr('data-cookie') != "undefined"){
            if (!mr_cookies.hasItem(modal.attr('data-cookie'))){
                setTimeout(function(){
                    modal.addClass('reveal-modal');
                    $('.modal-screen').addClass('reveal-modal');
                },delay);
            }
        } else{
            setTimeout(function(){
                modal.addClass('reveal-modal');
                $('.modal-screen').addClass('reveal-modal');
            },delay);
        }
    });

    // Exit modals
    $('.foundry_modal[data-show-on-exit]').each(function(){
        var modal = $(this);
        var exitSelector = $(modal.attr('data-show-on-exit'));
        // If a valid selector is found, attach leave event to show modal.
        if ($(exitSelector).length){
            modal.prepend($('<i class="ti-close close-modal">'));
            $(document).on('mouseleave', exitSelector, function(){
                if (!$('body .reveal-modal').length){
                    if (typeof modal.attr('data-cookie') !== typeof undefined){
                        if (!mr_cookies.hasItem(modal.attr('data-cookie'))){
                            modal.addClass('reveal-modal');
                            $('.modal-screen').addClass('reveal-modal');
                        }
                    } else{
                        modal.addClass('reveal-modal');
                        $('.modal-screen').addClass('reveal-modal');
                    }
                }
            });
        }
    });

    // Autoclose modals

    $('.foundry_modal[data-hide-after]').each(function(){
        var modal = $(this);
        var delay = modal.attr('data-hide-after');
        if (typeof modal.attr('data-cookie') != "undefined"){
            if (!mr_cookies.hasItem(modal.attr('data-cookie'))){
                setTimeout(function(){
                if (!modal.hasClass('modal-acknowledged')){
                    modal.removeClass('reveal-modal');
                    $('.modal-screen').removeClass('reveal-modal');
                }
                },delay); 
            }
        }else{
            setTimeout(function(){
                if (!modal.hasClass('modal-acknowledged')){
                    modal.removeClass('reveal-modal');
                    $('.modal-screen').removeClass('reveal-modal');
                }
            },delay); 
        }
    });
    
    jQuery('.close-modal:not(.modal-strip .close-modal)').unbind('click').click(function(){
        var modal = jQuery(this).closest('.foundry_modal');
        modal.toggleClass('reveal-modal');
        if (typeof modal.attr('data-cookie') !== "undefined"){
            mr_cookies.setItem(modal.attr('data-cookie'), "true", Infinity);
        }
        if (modal.find('iframe').length){
            modal.find('iframe').attr('src', '');
        }
        jQuery('.modal-screen').removeClass('reveal-modal');
    });
    
    jQuery('.modal-screen').unbind('click').click(function(){
        if (jQuery('.foundry_modal.reveal-modal').find('iframe').length){
            jQuery('.foundry_modal.reveal-modal').find('iframe').attr('src', '');
        }
        jQuery('.foundry_modal.reveal-modal').toggleClass('reveal-modal');
        jQuery(this).toggleClass('reveal-modal');
    });
    
    jQuery(document).keyup(function(e) {
         if (e.keyCode == 27) { // escape key maps to keycode `27`
            if (jQuery('.foundry_modal').find('iframe').length){
                jQuery('.foundry_modal').find('iframe').attr('src', '');
            }
            jQuery('.foundry_modal').removeClass('reveal-modal');
            jQuery('.modal-screen').removeClass('reveal-modal');
        }
    });
    
    // Modal Strips
    
    jQuery('.modal-strip').each(function(){
        if (!jQuery(this).find('.close-modal').length){
            jQuery(this).append(jQuery('<i class="ti-close close-modal">'));
        }
        var modal = jQuery(this);

        if (typeof modal.attr('data-cookie') != "undefined"){
           
            if (!mr_cookies.hasItem(modal.attr('data-cookie'))){
                setTimeout(function(){
                    modal.addClass('reveal-modal');
                },1000);
            }
        } else{
            setTimeout(function(){
                    modal.addClass('reveal-modal');
            },1000);
        }
    });
    
    jQuery('.modal-strip .close-modal').click(function(){
        var modal = jQuery(this).closest('.modal-strip');
        if (typeof modal.attr('data-cookie') != "undefined"){
            mr_cookies.setItem(modal.attr('data-cookie'), "true", Infinity);
        }
        jQuery(this).closest('.modal-strip').removeClass('reveal-modal');
        return false;
    });


    // Video Modals

    jQuery('.close-iframe').click(function() {
        jQuery(this).closest('.modal-video').removeClass('reveal-modal');
        jQuery(this).siblings('iframe').attr('src', '');
        jQuery(this).siblings('video').get(0).pause();
    });

    // Checkboxes

    $('.checkbox-option').on("click",function() {
        $(this).toggleClass('checked');
        var checkbox = $(this).find('input');
        if (checkbox.prop('checked') === false) {
            checkbox.prop('checked', true);
        } else {
            checkbox.prop('checked', false);
        }
    });

    // Radio Buttons

    $('.radio-option').click(function() {

        var checked = $(this).hasClass('checked'); // Get the current status of the radio

        var name = $(this).find('input').attr('name'); // Get the name of the input clicked

        if (!checked) {

            $('input[name="'+name+'"]').parent().removeClass('checked');

            $(this).addClass('checked');

            $(this).find('input').prop('checked', true);

        }

    });


    // Accordions

    $('.accordion li > .title').click(function() {
        if ($(this).closest('.accordion').hasClass('one-open')) {
            $(this).closest('.accordion').find('li').removeClass('active');
            $(this).parent().addClass('active');
        } else {
            $(this).parent().toggleClass('active');
        }
        if (typeof window.mr_parallax !== "undefined"){
            setTimeout(mr_parallax.windowLoad, 500);
        }
    });

    // Tabbed Content

    $('.tabbed-content').each(function() {
        $(this).append('<ul class="content"></ul>');
    });

    $('.tabs li').each(function() {
        var originalTab = $(this),
            activeClass = "";
        if (originalTab.is('.tabs>li:first-child')) {
            activeClass = ' class="active"';
        }
        var tabContent = originalTab.find('.tab-content').detach().wrap('<li' + activeClass + '></li>').parent();
        originalTab.closest('.tabbed-content').find('.content').append(tabContent);
    });

    $('.tabs li').click(function() {
        $(this).closest('.tabs').find('li').removeClass('active');
        $(this).addClass('active');
        var liIndex = $(this).index() + 1;
        $(this).closest('.tabbed-content').find('.content>li').removeClass('active');
        $(this).closest('.tabbed-content').find('.content>li:nth-of-type(' + liIndex + ')').addClass('active');
    });

    // Local Videos

    $('section').closest('body').find('.local-video-container .play-button').click(function() {
        $(this).siblings('.background-image-holder').removeClass('fadeIn');
        $(this).siblings('.background-image-holder').css('z-index', -1);
        $(this).css('opacity', 0);
        $(this).siblings('video').get(0).play();
    });

    // Youtube Videos

    $('section').closest('body').find('.player').each(function() {
        var section = $(this).closest('section');
        section.find('.container').addClass('fadeOut');
        var src = $(this).attr('data-video-id');
        var startat = $(this).attr('data-start-at');
        $(this).attr('data-property', "{videoURL:'http://youtu.be/" + src + "',containment:'self',autoPlay:true, mute:true, startAt:" + startat + ", opacity:1, showControls:false}");
    });

    if ($('.player').length){
        $('.player').each(function(){

            var section = $(this).closest('section');
            var player = section.find('.player');
            player.YTPlayer();
            player.on("YTPStart",function(e){
                section.find('.container').removeClass('fadeOut');
                section.find('.masonry-loader').addClass('fadeOut');
            });

        });
    }

    // Interact with Map once the user has clicked (to prevent scrolling the page = zooming the map

    /*
    $('.map-holder').click(function() {
        $(this).addClass('interact');
    });
    
    if ($('.map-holder').length){
        $(window).scroll(function() {
            if ($('.map-holder.interact').length) {
                $('.map-holder.interact').removeClass('interact');
            }
        });
    }
    */
    
    // Countdown Timers

    if ($('.countdown').length) {
        $('.countdown').each(function() {
            var date = $(this).attr('data-date');
            $(this).countdown(date, function(event) {
                $(this).text(
                    event.strftime('%D days %H:%M:%S')
                );
            });
        });
    }
    
    //                                                            //
    //                                                            //
    // Contact form code                                          //
    //                                                            //
    //                                                            //
    

    $('form.form-email, form.form-newsletter').submit(function(e) {

        // return false so form submits through jQuery rather than reloading page.
        if (e.preventDefault) e.preventDefault();
        else e.returnValue = false;

        var thisForm = $(this).closest('form.form-email, form.form-newsletter'),
            submitButton = thisForm.find('button[type="submit"]'),
            error = 0,
            originalError = thisForm.attr('original-error'),
            preparedForm, iFrame, userEmail, userFullName, userFirstName, userLastName, userPhoneNumber, userMessage, successRedirect, formError, formSuccess;

        // Mailchimp/Campaign Monitor Mail List Form Scripts
        iFrame = $(thisForm).find('iframe.mail-list-form');

        thisForm.find('.form-error, .form-success').remove();
        submitButton.attr('data-text', submitButton.text());
        thisForm.append('<div class="form-error" style="display: none;">' + thisForm.attr('data-error') + '</div>');
        thisForm.append('<div class="form-success" style="display: none;">' + thisForm.attr('data-success') + '</div>');
        formError = thisForm.find('.form-error');
        formSuccess = thisForm.find('.form-success');
        thisForm.addClass('attempted-submit');

        // Do this if there is an iframe, and it contains usable Mail Chimp / Campaign Monitor iframe embed code
        if ((iFrame.length) && (typeof iFrame.attr('srcdoc') !== "undefined") && (iFrame.attr('srcdoc') !== "")) {

            console.log('Mail list form signup detected.');
            if (typeof originalError !== typeof undefined && originalError !== false) {
                formError.html(originalError);
            }
            userEmail = $(thisForm).find('.signup-email-field').val();
            userFullName = $(thisForm).find('.signup-name-field').val();
            if ($(thisForm).find('input.signup-first-name-field').length) {
                userFirstName = $(thisForm).find('input.signup-first-name-field').val();
            } else {
                userFirstName = $(thisForm).find('.signup-name-field').val();
            }
            userLastName = $(thisForm).find('.signup-last-name-field').val();

            // validateFields returns 1 on error;
            if (validateFields(thisForm) !== 1) {
                preparedForm = prepareSignup(iFrame);

                preparedForm.find('#mce-EMAIL, #fieldEmail').val(userEmail);
                preparedForm.find('#mce-LNAME, #fieldLastName').val(userLastName);
                preparedForm.find('#mce-FNAME, #fieldFirstName').val(userFirstName);
                preparedForm.find('#mce-NAME, #fieldName').val(userFullName);
                thisForm.removeClass('attempted-submit');

                // Hide the error if one was shown
                formError.fadeOut(200);
                // Create a new loading spinner in the submit button.
                submitButton.html(jQuery('<div />').addClass('form-loading')).attr('disabled', 'disabled');
                
                try{
                    $.ajax({
                        url: preparedForm.attr('action'),
                        crossDomain: true,
                        data: preparedForm.serialize(),
                        method: "GET",
                        cache: false,
                        dataType: 'json',
                        contentType: 'application/json; charset=utf-8',
                        success: function(data){
                            // Request was a success, what was the response?
                            if (data.result != "success" && data.Status != 200) {
                                
                                // Error from Mail Chimp or Campaign Monitor

                                // Keep the current error text in a data attribute on the form
                                formError.attr('original-error', formError.text());
                                // Show the error with the returned error text.
                                formError.html(data.msg).fadeIn(1000);
                                formSuccess.fadeOut(1000);

                                submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');
                            } else {
                                
                                // Got Success from Mail Chimp
                                
                                submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');

                                successRedirect = thisForm.attr('success-redirect');
                                // For some browsers, if empty `successRedirect` is undefined; for others,
                                // `successRedirect` is false.  Check for both.
                                if (typeof successRedirect !== typeof undefined && successRedirect !== false && successRedirect !== "") {
                                    window.location = successRedirect;
                                }

                                thisForm.find('input[type="text"]').val("");
                                thisForm.find('textarea').val("");
                                formSuccess.fadeIn(1000);

                                formError.fadeOut(1000);
                                setTimeout(function() {
                                    formSuccess.fadeOut(500);
                                }, 5000);
                            }
                        }
                    });
                }catch(err){
                    // Keep the current error text in a data attribute on the form
                    formError.attr('original-error', formError.text());
                    // Show the error with the returned error text.
                    formError.html(err.message).fadeIn(1000);
                    formSuccess.fadeOut(1000);
                    setTimeout(function() {
                        formError.fadeOut(500);
                    }, 5000);

                    submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');
                }
            

                
            } else {
                formError.fadeIn(1000);
                setTimeout(function() {
                    formError.fadeOut(500);
                }, 5000);
            }
        } else {
            // If no iframe detected then this is treated as an email form instead.
            console.log('Send email form detected.');
            if (typeof originalError !== typeof undefined && originalError !== false) {
                formError.text(originalError);
            }
            
            error = validateFields(thisForm);

            if (error === 1) {
                formError.text("Unable to submit form. Please check fields above.").fadeIn(200);                
                setTimeout(function() {
                    formError.fadeOut(500);
                }, 3000);
            
            } else {
                var formData = {};
                formData["hostname"] = window.location.hostname;

                $(thisForm).find('.contact-field').each(function(){
                    var value;

                    if ($(this).is('select')) {
                        value = $(this).find(':selected').val();
                    }
                    else {
                        value = $(this).val();
                    }

                    if (value !== undefined) {
                        formData[$(this).attr('name')] = value;
                    }
                });

                thisForm.removeClass('attempted-submit');

                // Hide the error if one was shown
                formError.fadeOut(200);
                
                // Create a new loading spinner in the submit button.
                submitButton.html(jQuery('<div />').addClass('form-loading')).attr('disabled', 'disabled');

                $.ajax({
                    url: CONTACT_FORM_WEBHOOK,
                    method: "POST",
                    data: formData,
                    dataType: "json",
                    success: function(response) {                        
                        submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');
                        // For some browsers, if empty 'successRedirect' is undefined; for others,
                        // 'successRedirect' is false.  Check for both.
                        successRedirect = thisForm.attr('success-redirect');
                        if (typeof successRedirect !== typeof undefined && successRedirect !== false && successRedirect !== "") {
                            window.location = successRedirect;
                        }

                        thisForm.find('input[type="text"]').val("");
                        thisForm.find('textarea').val("");
                        thisForm.find('.form-success').fadeIn(1000);

                        formError.fadeOut(1000);
                        setTimeout(function() {
                            formSuccess.fadeOut(500);
                        }, 5000);
                    },
                    error: function(errorObject, errorText, errorHTTP) {
                        // Keep the current error text in a data attribute on the form
                        formError.attr('original-error', formError.text());
                        // Show the error with the returned error text.
                        formError.text("Unable to submit form.").fadeIn(1000);
                        formSuccess.fadeOut(1000);
                        submitButton.html(submitButton.attr('data-text')).removeAttr('disabled');
                    }
                });
            }
        }
        return false;
    });

    $('.validate-required, .validate-email').on('blur change', function() {
        validateFields($(this).closest('form'));
    });

    $('form').each(function() {
        if ($(this).find('.form-error').length) {
            $(this).attr('original-error', $(this).find('.form-error').text());
        }
    });

    function validateFields(form) {
            var name, error, originalErrorMessage;

            $(form).find('.validate-required[type="checkbox"]').each(function() {
                if (!$('[name="' + $(this).attr('name') + '"]:checked').length) {
                    error = 1;
                    name = $(this).attr('name').replace('[]', '');
                    form.find('.form-error').text('Please tick at least one ' + name + ' box.');
                }
            });

            $(form).find('.validate-required').each(function() {
                if ($(this).val() === '') {
                    $(this).addClass('field-error');
                    error = 1;
                } else {
                    $(this).removeClass('field-error');
                }
            });

            $(form).find('.validate-email').each(function() {
                if (!(/(.+)@(.+){2,}\.(.+){2,}/.test($(this).val()))) {
                    $(this).addClass('field-error');
                    error = 1;
                } else {
                    $(this).removeClass('field-error');
                }
            });

            if (!form.find('.field-error').length) {
                form.find('.form-error').fadeOut(1000);
            }

            return error;
        }

    //
    //    
    // End contact form code
    //
    //


    // Get referrer from URL string 
    if (getURLParameter("ref")) {
        $('form.form-email').append('<input type="text" name="referrer" class="hidden" value="' + getURLParameter("ref") + '"/>');
    }

    function getURLParameter(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null;
    }

    // Disable parallax on mobile
     
    if (isMobile) {
        $('section').removeClass('parallax');
    }
    
    // Disqus Comments
    
    if ($('.disqus-comments').length){
        // CONFIGURATION VARIABLES //
        var disqus_shortname = $('.disqus-comments').attr('data-shortname');

        // DON'T EDIT BELOW THIS LINE //
        (function() {
            var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;
            dsq.src = '//' + disqus_shortname + '.disqus.com/embed.js';
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);
        })();
    }

    // Load Google MAP API JS with callback to initialise when fully loaded
    if (document.querySelector('[data-maps-api-key]') && !document.querySelector('.gMapsAPI')){
        if ($('[data-maps-api-key]').length){
            var script = document.createElement('script');
            var apiKey = $('[data-maps-api-key]:first').attr('data-maps-api-key');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?key='+apiKey+'&callback=initializeMaps';
            script.className = 'gMapsAPI';
            document.body.appendChild(script);  
        } 
    }

    /* CUSTOM CODE */

    // Overlay opacity

    // See: 
    // https://davidwalsh.name/add-rules-stylesheets
    // http://stackoverflow.com/questions/5041494/selecting-and-manipulating-css-pseudo-elements-such-as-before-and-after-usin/21709814#21709814

    var sheet = (function() {
        var style = document.createElement("style");
        style.appendChild(document.createTextNode("")); // WebKit hack        
        document.head.appendChild(style);
        return style.sheet;
    })();

    var sheetXs = (function() {        
        var style = document.createElement("style");    
        style.setAttribute("media", "all and (max-width : 767px)");
        style.appendChild(document.createTextNode("")); // WebKit hack        
        document.head.appendChild(style);
        return style.sheet;
    })();

    function addCSSRule(sheet, selector, rules, index) {
        if("insertRule" in sheet) {
            sheet.insertRule(selector + "{" + rules + "}", index);
        }
        else if("addRule" in sheet) {
            sheet.addRule(selector, rules, index);
        }
    }

    // Check the stylesheet for the specified selector
    function selectorInStylesheet(sheet, selector) {        
        var cssRules = sheet.rules ? sheet.rules : sheet.cssRules;

        for (var i = 0; i < cssRules.length; ++i) {
            if (cssRules[i].selectorText == selector) return true;
        }
        return false;
    }


    $('.background-image-holder').each(function() {
        var overlayOpacity = $(this).attr('data-overlay-opacity');
        var overlayOpacityXs = $(this).attr('data-overlay-opacity-xs');

        if (overlayOpacity !== undefined) {
            var overlayClass = ('overlay-' + overlayOpacity.toString()).replace(/\./g,'_');

            $(this).addClass('overlay ' + overlayClass);

            addCSSRule(sheet, '.' + overlayClass + ':before', 'opacity: ' + overlayOpacity, 0);
        }

        if (overlayOpacityXs !== undefined) {
            var overlayClassXs = ('overlay-xs-' + overlayOpacityXs.toString()).replace(/\./g,'_');

            $(this).addClass('overlay-xs ' + overlayClassXs);

            addCSSRule(sheetXs , '.' + overlayClassXs + ':before', 'opacity: ' + overlayOpacityXs, 0);
        } 
    });

    // Read more

    $('.readmore').click(function() {
        var sibling = 
        $(this).next().removeClass('hidden');
        $(this).hide();    
    });


    // Enable dialable links on mobile

    if (isMobile) {
        $('a.dialable').each(function() {
            $(this).prop("href", "tel:1" + $(this).text().replace(/[^0-9]/gi, ''));
        });
    }    

    // Hide on mobile or desktop

    if (isMobile) {
        $('.desktop-only').css('display', 'none');
    } else {
        $('.mobile-only').css('display', 'none');
    }
    
    // Hide/display depending on whether this is home
    if (isHome) {
        $('.hidden-home').css('display', 'none');
    }
    else {
        $('.hidden-non-home').css('display', 'none');        
    }


    // Offset page for inner links

    function offsetAnchor() {
        if(location.hash.length !== 0) {
            window.scrollTo(window.scrollX, window.scrollY - 100);
        }
    }

    window.addEventListener("hashchange", offsetAnchor);

    // This is here so that when you enter the page with a hash,
    // it can provide the offset in that case too. Having a timeout
    // seems necessary to allow the browser to jump to the anchor first.
    window.setTimeout(offsetAnchor, 1); // The delay of 1 is arbitrary and may not always work right (although it did in my testing).

    
    $('a').each(function() {
        var appendToLink = $(this).attr('data-append-to-link');
        if (appendToLink !== undefined) {
            var href = $(this).attr('href');
            $(this).attr('href', href + appendToLink);
        }        
    });


    // Update scroll variable for scrolling functions

    scrollYOffset = window.pageYOffset;
    
    addEventListener('scroll', function() {
        var newscrollYOffset;
        newscrollYOffset = window.pageYOffset;
        scrollingUp = newscrollYOffset < scrollYOffset;
        scrollYOffset = newscrollYOffset;
    }, false);

    
    // Navigation

    nav = $('body .nav-container nav:first');    
    navUtility = $('body .nav-container .nav-utility:first');
    navHeader = $('body .nav-container .nav-header:first');
    navMenu = $('body .nav-container .nav-menu:first');

    navOverlay = nav.hasClass('nav-overlay');    

    navTransparentXs = nav.hasClass('nav-transparent-xs');
    navTransparentSm = nav.hasClass('nav-transparent-sm');
    navTransparentMdOrLg = nav.hasClass('nav-transparent-md-or-lg');

    navRetractOnScrollUpXs = nav.hasClass('nav-retract-on-scroll-up-xs');
    navRetractOnScrollUpSm = nav.hasClass('nav-retract-on-scroll-up-sm');
    navRetractOnScrollUpMdOrLg = nav.hasClass('nav-retract-on-scroll-up-md-or-lg');
    
    navScrollAtXs = nav.attr('data-nav-scroll-at-xs');
    navScrollAtSm = nav.attr('data-nav-scroll-at-sm');
    navScrollAtMdOrLg = nav.attr('data-nav-scroll-at-md-or-lg');
    
    if (navOverlay) {
        $('body').addClass('nav-is-overlay');
    }
    
    if ($('nav').hasClass('bg-dark')) {
        $('.nav-container').addClass('bg-dark');
    }
            
    window.addEventListener("resize", updateNav, false);    
    window.addEventListener("scroll", updateNav, false);
    
    updateNav();  

  
    // On Chrome mobile browser, the window size changes as you scroll and the URL bar hides away. The fix below ensures that elements whose heights depend on window size stay constant.
    
    function stabilizeHeight(element) {
        var originalMaxHeight = parseInt($(element).css('max-height'));

        var HEIGHT_CHANGE_TOLERANCE = 100; // Approximate height of URL bar
        var viewportHeight = window.innerHeight;
        var elementHeight = element.offsetHeight;
        var originalElementPercent = element.offsetHeight / viewportHeight;

        window.addEventListener('resize', function() {
            if (isMobile) {
                if (Math.abs(viewportHeight - window.innerHeight) > HEIGHT_CHANGE_TOLERANCE) {
                    viewportHeight = window.innerHeight;
                    elementHeight = window.innerHeight * originalElementPercent;
                    update();
                }
            }
            else {
                update();
            }
        });

        // TODO: Change hardcoded numbers below to variables shared with CSS file

        function update() {
            if ($(element).hasClass('hero-section-fullscreen')) {
                if (!nav.hasClass('nav-overlay')) {
                    var newHeight = $(window).height() - navUnscrolledHeight;

                    var newMaxHeight = originalMaxHeight - navUnscrolledHeight;
                    var newMinHeight = 620 - navUnscrolledHeight;

                    if (Modernizr.mq('(max-width: 767px) and (orientation: portrait)')) {
                        newMinHeight = 610 - navUnscrolledHeight;
                    }
                    else if (Modernizr.mq('(max-width: 767px) and (orientation: landscape)')) {
                        newMinHeight = 460 - navUnscrolledHeight;
                    }
            
                    $(element).css('height', newHeight + 'px');
                    $(element).css('min-height', newMinHeight + 'px');
                    $(element).css('max-height', newMaxHeight + 'px');
                }
                else {
                    element.style.height = elementHeight + "px";    
                }
            } 
            else {
                element.style.height = elementHeight + "px";
            }        
        }

        update();
    }

    
    var elements = document.querySelectorAll(".hero-section-fullscreen");
    for (var i = 0; i < elements.length; i++) {
        stabilizeHeight(elements[i]);
    }
    
    $('.convert-to-html').each(function(){
        var html = $.parseHTML($(this).contents().text());
        $(this).replaceWith(html);
    });
    
    $('.slider .slide').each(function() {
        var width = 0;
        var slideWidthAttr = $(this).attr('data-slide-width');        

        if (slideWidthAttr !== undefined) {
            width = parseInt(slideWidthAttr);
        }
        else {
            var img = $(this).children('img:first');

            if (img !== undefined) { 
                width = img.outerWidth(true);
            }            
        }

        var slideSpacingAttr = $(this).attr('data-slide-spacing');        

        if (slideSpacingAttr !== undefined) {
            width += parseInt(slideSpacingAttr);
        }

        var widthClass = ('width-' + width).replace(/\./g,'_');
        
        if (!selectorInStylesheet(sheet, '.' + widthClass)) {
            addCSSRule(sheet, '.' + widthClass, 'width: ' + width + 'px', 0);
        }

        $(this).addClass(widthClass);
    });

    var hideShowSliderArrows = function(args) {        
        var sliderContainer = $(args.sliderContainerObject);
        var parentWidth = sliderContainer.parent().innerWidth();
        var next = sliderContainer.find('~ .next:first');
        var prev = sliderContainer.find('~ .prev:first');

        if (prev !== undefined) {
            parseInt(sliderContainer.css('max-width')) < parentWidth ? prev.hide() : prev.show();
        }
        if (next !== undefined) {
            parseInt(sliderContainer.css('max-width')) < parentWidth ? next.hide() : next.show();
        }
    };

    $('.slider-container').each(function() {
        var sliderContainer = $(this);
        var slider = sliderContainer.find('.slider:first');
        var width = 0;
        var next = sliderContainer.find('~ .next:first');
        var prev = sliderContainer.find('~ .prev:first');

        $(slider).children('.slide').each(function() {            
            width += $(this).outerWidth(true);                        
        });

        sliderContainer.css('max-width', width + 1 + 'px');

        var draggable = sliderContainer.attr('data-draggable');
        var snapToChildren = sliderContainer.attr('data-snap-to-children');
                
        sliderContainer.iosSlider({
            desktopClickDrag: draggable !== undefined && draggable == 'true',
            snapToChildren: snapToChildren !== undefined && snapToChildren == 'true',
            navNextSelector: next,
            navPrevSelector: prev,
            unselectableSelector: draggable !== undefined && draggable == 'false' ? sliderContainer : null,
            onSliderLoaded: hideShowSliderArrows,
            onSliderResize: hideShowSliderArrows
        });
    });
    
    $('.loading-overlay').fadeOut();

}); 

$(window).load(function() { 
    "use strict";

    // Initialize Masonry

    setTimeout(initializeMasonry, 1000);

    // Initialize twitter feed

    var setUpTweets = setInterval(function() {
        if ($('.tweets-slider').find('li.flex-active-slide').length) {
            clearInterval(setUpTweets);
            return;
        } else {
            if ($('.tweets-slider').length) {
                $('.tweets-slider').flexslider({
                    directionNav: false,
                    controlNav: false
                });
            }
        }
    }, 500);    
}); 


function updateNavContainerHeight() {
    var extraSmallWindow = Modernizr.mq('(max-width: 767px)');
    var smallWindow = Modernizr.mq('(min-width: 768px) and (max-width: 991px)');    
    //var mediumOrLargeWindow = Modernizr.mq('(min-width: 992px)');

    var navHeaderContainer = $('body .nav-container .nav-header-container:first');

    if (navUtilityHeight == 0) {
        navUtilityHeight = navUtility !== undefined ? navUtility.outerHeight() : 0;
    }
    
    if (navHeaderHeight == 0) {
        navHeaderHeight = navHeader !== undefined ? navHeader.outerHeight() : 0;
    }
    
    navUtilityUnscrolledHeight = navUtility !== undefined && !navUtility.hasClass('hidden') && !navUtility.hasClass('hidden-unscrolled') ? navUtilityHeight : 0;

    navUtilityScrolledHeight = navUtility !== undefined && !navUtility.hasClass('hidden') && !navUtility.hasClass('hidden-scrolled') ? navUtilityHeight : 0;
        
    navHeaderUnscrolledHeight = navHeaderContainer !== undefined && !navHeaderContainer.hasClass('hidden') && !navHeaderContainer.hasClass('hidden-unscrolled') ? navHeaderHeight : 0;

    navHeaderScrolledHeight = navHeaderContainer !== undefined && !navHeaderContainer.hasClass('hidden') && !navHeaderContainer.hasClass('hidden-scrolled') ? navHeaderHeight : 0;

    if (extraSmallWindow) {
        //console.log("EXTRA_SMALL_WINDOW");
        navTransparent = navTransparentXs;

        if (navUtility !== undefined && (navUtility.hasClass('hidden-unscrolled-xs') || navUtility.hasClass('hidden-xs'))) {
            navUtilityUnscrolledHeight = 0;
        }
        if (navUtility !== undefined && (navUtility.hasClass('hidden-scrolled-xs') || navUtility.hasClass('hidden-xs'))) {
            navUtilityScrolledHeight = 0;
        }        

        if (navHeaderContainer !== undefined && (navHeaderContainer.hasClass('hidden-unscrolled-xs') || navHeaderContainer.hasClass('hidden-xs'))) {
            navHeaderUnscrolledHeight = 0;
        }

        if (navHeaderContainer !== undefined && (navHeaderContainer.hasClass('hidden-scrolled-xs') || navHeaderContainer.hasClass('hidden-xs'))) {
            navHeaderScrolledHeight = 0;
        }
    }
    else if (smallWindow) {
        //console.log("SMALL_WINDOW");
        navTransparent = navTransparentSm;
        
        if (navUtility !== undefined && (navUtility.hasClass('hidden-unscrolled-sm') || navUtility.hasClass('hidden-sm'))) {
            navUtilityUnscrolledHeight = 0;
        }
        
        if (navUtility !== undefined && (navUtility.hasClass('hidden-scrolled-sm') || navUtility.hasClass('hidden-sm'))) {
            navUtilityScrolledHeight = 0;
        }

        if (navHeaderContainer !== undefined && (navHeaderContainer.hasClass('hidden-unscrolled-sm') || navHeaderContainer.hasClass('hidden-sm'))) {
            navHeaderUnscrolledHeight = 0;
        }

        if (navHeaderContainer !== undefined && (navHeaderContainer.hasClass('hidden-scrolled-sm') || navHeaderContainer.hasClass('hidden-sm'))) {
            navHeaderScrolledHeight = 0;
        }
    }
    else {
        //console.log("LARGE_WINDOW");
        navTransparent = navTransparentMdOrLg;

        if (navUtility !== undefined && (navUtility.hasClass('hidden-unscrolled-md-or-lg') || navUtility.hasClass('hidden-md-or-lg'))) {
            navUtilityUnscrolledHeight = 0;
        }

        if (navUtility !== undefined && (navUtility.hasClass('hidden-scrolled-md-or-lg') || navUtility.hasClass('hidden-md-or-lg'))) {
            navUtilityScrolledHeight = 0;
        }

        if (navHeaderContainer !== undefined && (navHeaderContainer.hasClass('hidden-unscrolled-md-or-lg') || navHeaderContainer.hasClass('hidden-md-or-lg'))) {
            navHeaderUnscrolledHeight = 0;
        }

        if (navHeaderContainer !== undefined && (navHeaderContainer.hasClass('hidden-scrolled-md-or-lg') || navHeaderContainer.hasClass('hidden-md-or-lg'))) {
            navHeaderScrolledHeight = 0;
        }
    }
    
    if (navTransparent) {
        nav.addClass('transparent');
    }
    else {
        nav.removeClass('transparent');   
    }

    navMenuHeight = navMenu !== undefined ? navMenu.outerHeight() : 0;

    /*
    $('body .nav-container .nav-header-container:first').css('height', navHeaderHeight);
    */

    navUnscrolledHeight = navUtilityUnscrolledHeight + navHeaderUnscrolledHeight + (!navTransparent ? navMenuHeight : 0);
    navScrolledHeight = navUtilityScrolledHeight + navHeaderScrolledHeight + navMenuHeight;

    navMenuTopY = navUtilityUnscrolledHeight + navHeaderUnscrolledHeight;
    navMenuBottomY = navMenuTopY + navMenuHeight;

    if (!navOverlay) {
        // console.log("navUtilityHeight: " + navUtilityUnscrolledHeight);
        // console.log("navHeaderContainerHeight: " + navHeaderUnscrolledHeight);
        // console.log("navMenuHeight: " + navMenuHeight);
        // console.log("navContainerHeight: " + navContainerHeight);

        $('.nav-container').css('min-height', navUnscrolledHeight);

        /*
        // Compensate the height of parallax element for inline nav

        if ($(window).width() > 768) {
            $('.parallax:nth-of-type(1) .background-image-holder').css('top', -($('nav').outerHeight(true)));
        }

        // Adjust fullscreen elements

        if ($(window).width() > 768) {
            $('section.fullscreen:nth-of-type(1)').css('height', ($(window).height() - $('nav').outerHeight(true)));
        }*/
    }    
}


function updateNav() {
    if ($('.drawer-menu.active').length === 0 && $('.mfp-bg').length === 0) {
        updateNavContainerHeight();
                        
        var extraSmallWindow = Modernizr.mq('(max-width: 767px)');
        var smallWindow = Modernizr.mq('(min-width: 768px) and (max-width: 991px)');    
        //var mediumOrLargeWindow = Modernizr.mq('(min-width: 992px)');    

        var firstSection = $('.main-container section:nth-of-type(1)');
        var firstSectionBottomY = firstSection.position().top + firstSection.outerHeight(true);    

        var navRetractOnScrollUp = false,        
            navScrollAt;

        if (extraSmallWindow) {        
            navScrollAt = navScrollAtXs;
            navRetractOnScrollUp = navRetractOnScrollUpXs;
        }
        else if (smallWindow) {        
            navScrollAt = navScrollAtSm;
            navRetractOnScrollUp = navRetractOnScrollUpSm;
        }
        else {        
            navScrollAt = navScrollAtMdOrLg;
            navRetractOnScrollUp = navRetractOnScrollUpMdOrLg;
        }

        var setNavFixed = function(fixed) {
            if (fixed && !navFixed) {
                // console.log("setFixed: ".concat(fixed));
                navFixed = true;
                nav.addClass('fixed');            
            }
            else if (!fixed && navFixed) {
                // console.log("setFixed: ".concat(fixed));
                navFixed = false;
                nav.removeClass('fixed');
            }
        };

        var setNavScrolled = function(scrolled) {
            if (scrolled && !navScrolled) {
                // console.log("setScrolled: ".concat(scrolled));
                navScrolled = true;
                nav.addClass('scrolled');            
            }
            else if (!scrolled && navScrolled) {
                // console.log("setScrolled: ".concat(scrolled));
                navScrolled = false;
                nav.removeClass('scrolled');        
            }
        };

        var setNavOutOfSight = function(outOfSight) {        
            if (outOfSight && !navOutOfSight) {
                // console.log("setOutOfSight: ".concat(outOfSight));            
                navOutOfSight = true;
                nav.addClass('outOfSight');
            }
            else if (!outOfSight && navOutOfSight) {
                // console.log("setOutOfSight: ".concat(outOfSight));
                navOutOfSight = false;
                nav.removeClass('outOfSight');
            }
        };

        if (navScrollAt !== undefined) {            
            if (!scrollingUp) {                
                //if (!navScrolled || navOutOfSight) {
                    if (navScrollAt === 'windowTop') {
                        setNavScrolled(scrollYOffset >= 0);
                        setNavOutOfSight(false);
                        setNavFixed(false);
                    }
                    if (navScrollAt === 'menuTop') {
                        setNavScrolled(scrollYOffset > navMenuTopY);                    
                        setNavOutOfSight(false);
                        setNavFixed(false);
                    }
                    else if (navScrollAt === 'midFirstSection') {
                        if (!navFixed) {
                            setNavOutOfSight(scrollYOffset >= navMenuBottomY + 60 && scrollYOffset < firstSectionBottomY/1.5);
                        }
                        setNavScrolled(scrollYOffset >= firstSectionBottomY/1.5);
                        setNavFixed(false);
                    }
                    else if (navScrollAt === 'midFirstSectionWhileFixed') {
                        setNavFixed(true);
                        setNavScrolled(scrollYOffset >= firstSectionBottomY/1.5);
                        setNavOutOfSight(false);
                    }
                //}            
            }
            else {
                if (!navScrolled && navOutOfSight) {
                    setNavOutOfSight(scrollYOffset >= navMenuBottomY + 60);                
                }
                else if (navScrolled) {
                    if (navRetractOnScrollUp) {
                        setNavOutOfSight(scrollYOffset >= navMenuBottomY + 60 && scrollYOffset < firstSectionBottomY/1.5);
                        setNavScrolled(scrollYOffset >= navMenuBottomY + 60);
                    }                
                    else {                    
                        setNavOutOfSight(false);

                        if (navScrollAt !== 'windowTop') {                        
                            setNavScrolled(scrollYOffset - navUtilityScrolledHeight > navMenuTopY);
                        }                    
                    }
                }            
            }    
            if (navScrollAt === 'windowTop' || navScrollAt === 'midFirstSectionWhileFixed' && scrollYOffset == 0) {
                setNavFixed(true);
            }
        }
        else {
            setNavScrolled(false);
            setNavFixed(false);
            setNavOutOfSight(false);        
        }

        $.smoothScroll("options", {offset: -1 * navScrolledHeight});

        updateNavContainerHeight();
    }
}


function capitaliseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function initializeMasonry(){
    $('.masonry').each(function(){
        var container = $(this).get(0);
        var msnry = new Masonry(container, {
            itemSelector: '.masonry-item'
        });

        msnry.on('layoutComplete', function() {

            var firstSectionHeight = $('.main-container section:nth-of-type(1)').outerHeight(true);

            // Fix floating project filters to bottom of projects container

            if ($('.filters.floating').length) {
                setupFloatingProjectFilters();
                updateFloatingFilters();
                window.addEventListener("scroll", updateFloatingFilters, false);
            }

            $('.masonry').addClass('fadeIn');
            $('.masonry-loader').addClass('fadeOut');
            if ($('.masonryFlyIn').length) {
                masonryFlyIn();
            }
        });

        msnry.layout();
    });
}

function masonryFlyIn() {
    var $items = $('.masonryFlyIn .masonry-item');
    var time = 0;

    $items.each(function() {
        var item = $(this);
        setTimeout(function() {
            item.addClass('fadeIn');
        }, time);
        time += 170;
    });
}

function setupFloatingProjectFilters() {
    floatingProjectSections = [];
    $('.filters.floating').closest('section').each(function() {
        var section = $(this);

        floatingProjectSections.push({
            section: section.get(0),
            outerHeight: section.outerHeight(),
            elemTop: section.offset().top,
            elemBottom: section.offset().top + section.outerHeight(),
            filters: section.find('.filters.floating'),
            filersHeight: section.find('.filters.floating').outerHeight(true)
        });
    });
}

function updateFloatingFilters() {
    var l = floatingProjectSections.length;
    while (l--) {
        var section = floatingProjectSections[l];

        if ((section.elemTop < scrollYOffset) && typeof window.mr_variant == "undefined" ) {
            section.filters.css({
                position: 'fixed',
                top: '16px',
                bottom: 'auto'
            });
            if (navScrolled) {
                section.filters.css({
                    transform: 'translate3d(-50%,48px,0)'
                });
            }
            if (scrollYOffset > (section.elemBottom - 70)) {
                section.filters.css({
                    position: 'absolute',
                    bottom: '16px',
                    top: 'auto'
                });
                section.filters.css({
                    transform: 'translate3d(-50%,0,0)'
                });
            }
        } else {
            section.filters.css({
                position: 'absolute',
                transform: 'translate3d(-50%,0,0)'
            });
        }
    }
}

window.initializeMaps = function(){
    if (typeof google !== "undefined"){
        if (typeof google.maps !== "undefined"){
            $('.map-canvas[data-maps-api-key]').each(function(){
                    var mapInstance   = this,
                        mapJSON       = typeof $(this).attr('data-map-style') !== "undefined" ? $(this).attr('data-map-style'): false,
                        mapStyle      = JSON.parse(mapJSON) || [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}],
                        zoomLevel     = (typeof $(this).attr('data-map-zoom') !== "undefined" && $(this).attr('data-map-zoom') !== "") ? $(this).attr('data-map-zoom') * 1: 17,
                        latlong       = typeof $(this).attr('data-latlong') != "undefined" ? $(this).attr('data-latlong') : false,
                        latitude      = latlong ? 1 *latlong.substr(0, latlong.indexOf(',')) : false,
                        longitude     = latlong ? 1 * latlong.substr(latlong.indexOf(",") + 1) : false,
                        geocoder      = new google.maps.Geocoder(),
                        address       = typeof $(this).attr('data-address') !== "undefined" ? $(this).attr('data-address').split(';'): [""],
                        markerTitle   = "We Are Here",
                        isDraggable = $(document).width() > 766 ? true : false,
                        map, marker, markerImage,
                        mapOptions = {
                            draggable: isDraggable,
                            scrollwheel: false,
                            zoom: zoomLevel,
                            disableDefaultUI: true,
                            styles: mapStyle
                        };

                    if ($(this).attr('data-marker-title') !== undefined && $(this).attr('data-marker-title') !== "" )
                    {
                        markerTitle = $(this).attr('data-marker-title');
                    }

                    if (address !== undefined && address[0] !== ""){
                            geocoder.geocode( { 'address': address[0].replace('[nomarker]','')}, function(results, status) {
                                if (status == google.maps.GeocoderStatus.OK) {
                                var map = new google.maps.Map(mapInstance, mapOptions); 
                                map.setCenter(results[0].geometry.location);
                                
                                address.forEach(function(address){
                                    var markerGeoCoder;
                                    
                                    markerImage = {url: window.mr_variant === undefined ? 'img/mapmarker.png' : '../img/mapmarker.png', size: new google.maps.Size(50,50), scaledSize: new google.maps.Size(50,50)};
                                    if (/(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)/.test(address) ){
                                        var latlong = address.split(','),
                                        marker = new google.maps.Marker({
                                                        position: { lat: 1*latlong[0], lng: 1*latlong[1] },
                                                        map: map,
                                                        icon: markerImage,
                                                        title: markerTitle,
                                                        optimised: false
                                                    });
                                    }
                                    else if (address.indexOf('[nomarker]') < 0){
                                        markerGeoCoder = new google.maps.Geocoder();
                                        markerGeoCoder.geocode( { 'address': address.replace('[nomarker]','')}, function(results, status) {
                                            if (status == google.maps.GeocoderStatus.OK) {
                                                marker = new google.maps.Marker({
                                                    map: map,
                                                    icon: markerImage,
                                                    title: markerTitle,
                                                    position: results[0].geometry.location,
                                                    optimised: false
                                                });
                                            }
                                        });
                                    }

                                });
                            } else {
                                console.log('There was a problem geocoding the address.');
                            }
                        });
                    }
                    else if (latitude !== undefined && latitude !== "" && latitude !== false && longitude !== undefined && longitude !== "" && longitude !== false ){
                        mapOptions.center   = { lat: latitude, lng: longitude};
                        map = new google.maps.Map(mapInstance, mapOptions); 
                        marker              = new google.maps.Marker({
                                                    position: { lat: latitude, lng: longitude },
                                                    map: map,
                                                    icon: markerImage,
                                                    title: markerTitle
                                                });

                    }

                }); 
        }
    }
};
initializeMaps();

// End of Maps




// Prepare Signup Form - It is used to retrieve form details from an iframe Mail Chimp or Campaign Monitor form.

function prepareSignup(iFrame){
    var form   = jQuery('<form />'),
        div    = jQuery('<div />'),
        action;

    jQuery(div).html(iFrame.attr('srcdoc'));
    action = jQuery(div).find('form').attr('action');



    // Alter action for a Mail Chimp-compatible ajax request url.
    if (/list-manage\.com/.test(action)){
       action = action.replace('/post?', '/post-json?') + "&c=?";
       if (action.substr(0,2) == "//"){
           action = 'http:' + action;
       }
    }

    // Alter action for a Campaign Monitor-compatible ajax request url.
    if (/createsend\.com/.test(action)){
       action = action + '?callback=?';
    }


    // Set action on the form
    form.attr('action', action);

    // Clone form input fields from 
    jQuery(div).find('input, select, textarea').not('input[type="submit"]').each(function(){
        jQuery(this).clone().appendTo(form);

    });

    return form;
        

}



/*\
|*|  COOKIE LIBRARY THANKS TO MDN
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  Revision #1 - September 4, 2014
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
|*|  https://developer.mozilla.org/User:fusionchess
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * mr_cookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * mr_cookies.getItem(name)
|*|  * mr_cookies.removeItem(name[, path[, domain]])
|*|  * mr_cookies.hasItem(name)
|*|  * mr_cookies.keys()
|*|
\*/

var mr_cookies = {
  getItem: function (sKey) {
    if (!sKey) { return null; }
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toUTCString();
          break;
      }
    }
    document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
    return true;
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!this.hasItem(sKey)) { return false; }
    document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
    return true;
  },
  hasItem: function (sKey) {
    if (!sKey) { return false; }
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },
  keys: function () {
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
    for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
    return aKeys;
  }
};

/*\
|*|  END COOKIE LIBRARY
\*/