$(document).ready(function(){
    var $body = $('body'),    
        $mainContainer = $('.main-container'),
        mainContainerScrollTop = 0,
        bodyScrollTop = 0;

    var gallery = {
        init : function(){
            $('.magnific').magnificPopup({
                type: 'image',                
                delegate: 'a',
                fixedContentPos: true,
                gallery:{
                    enabled:true
                },
                callbacks: {
                    beforeOpen: function() {
                        bodyScrollTop = $body.scrollTop();

                        $mainContainer.css({
                            'position':'fixed',
                            'top':-1*(window.pageYOffset - $mainContainer.offset().top),
                            'left':'0',
                            'width':'100%'
                        });
                    },
                    close: function() {
                        $mainContainer.removeAttr('style');
                        $body.scrollTop(bodyScrollTop);
                    }
                }
            });
        }
    }

    gallery.init();
});