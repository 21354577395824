$(document).ready(function() {
    var FN = {
        drawerMenu: function() {
            var $body = $('body'),
                $hamburger = $('.drawer-open'),
                $overlay = $('.drawer-overlay'),
                $menu = $('.drawer-menu'),
                $close = $('.drawer-close'),
                $layer = $('.drawer-layer'),
                $inner = $('.drawer-inner'),
                $links = $inner.find('.nav-links'),
                $items = $links.find('.nav-items'),
                $mainContainer = $('.main-container'),
                mainContainerScrollTop = 0,
                bodyScrollTop = 0;

            function _closeDrawer() {
                //$body.removeClass('drawer-open');
                //$body.removeAttr('style');
                
                $mainContainer.removeAttr('style');
                
                $menu.removeClass('active');
                $overlay.stop().fadeOut();
                $overlay.removeClass('active');

                $layer.off('wheel mousewheel DOMMouseScroll scroll touchmove');

                //$('html, body').scrollTop(offset);
                $('html, body').scrollTop(bodyScrollTop);
                
            }

            function _openDrawer() {
                /*$body.addClass('drawer-open');*/
                
                $overlay.stop().fadeIn();
                $overlay.addClass('active');
                $menu.addClass('active');

                $menu.scrollTop(0);
                /*
                $items.css({
                    'min-height': $items.outerHeight() + 'px'
                });
                */

                $links.off('wheel mousewheel DOMMouseScroll scroll touchmove');                                
                
                bodyScrollTop = $('html, body').scrollTop();

                $mainContainer.css({
                    'position':'fixed',
                    'top':-1*(window.pageYOffset - $mainContainer.offset().top),
                    'left':'0',
                    'width':'100%'
                });
                /*
                $body.css({
                    'position':'fixed',
                    'top':-bodyScrollTop,
                    'left':'0',
                    'width':'100%'
                });*/
            }             

            $('.drawer-menu a.has-dropdown').on('click',function(){
                var $this = $(this),
                    $li = $this.closest('li'),
                    $drop = $li.find('ul');

                if ($li.hasClass('active')) {      
                    /*$items.css({
                        'min-height': ($items.outerHeight() - $drop.outerHeight()) + 'px'});*/
                    $drop.slideUp(300, function() {
                        
                    });
                    $li.removeClass('active');
                } else {
                    $drop.slideDown(300, function() {
                        /*$items.css({
                            'min-height': $items.outerHeight() + 'px'
                        });*/
                    });
                    $li.addClass('active');
                }
                return false;
            });

            $('.drawer-menu a:not(.has-dropdown)').on('click',function(){ 
                _closeDrawer();
            });

            $hamburger.on('click', function() {
                if ($(this).hasClass('hamburger-right')) {
                    console.log('has');
                    $menu.addClass('right-drawer');
                } else {
                    $menu.removeClass('right-drawer');
                }

                if (!$overlay.hasClass('active')) {
                    _openDrawer();
                } else {
                    _closeDrawer();
                }

                return false;
            });

            $layer.on('wheel mousewheel DOMMouseScroll scroll touchmove', function(e) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            });

            $layer.on('click', function() {
                _closeDrawer();
            });

            $close.on('click', function() {
                _closeDrawer();
                return false;
            });

            /*
            $items.css({
                'min-height': $items.outerHeight() + 'px'
            });            
            */

            $(window).resize(function() {
                /*
                $items.css({
                    'min-height': $items.outerHeight() + 'px'
                });*/
                if ($overlay.hasClass('active') && $(window).width() > 991) {
                    _closeDrawer();
                }
            });
        }
    }

    for (var i in FN) {
        FN[i]();
    }
});